/**
 * Component responsible for the content in App sidebar.
 */

import { Icon, Layout, Menu } from 'antd';
import {
    compact,
    debounce,
    forEach,
    includes,
    isEmpty,
    isNil,
    map,
} from 'lodash';
import React, {
    memo,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserRole } from '../../store/roles/sagas';
import { setMenuCollapsedAction } from '../../store/storedRoute/actions';
import { getIfIsLg } from '../../utils/commonFunctions';
import FontAwesome from '../common/FontAwesome';
const { Sider } = Layout;
const { SubMenu } = Menu;
let unmounted = false;
interface IProps {
    readonly menuItems: any;
    history: {
        push: (path: string) => void;
    };
    location: {
        pathname: string;
    };
    match: {
        path: string;
    };
}
let currentSelectedKeys: string[] = [];
let currentOpenKeys: string[] = [];
var intialWindowSizeLoadDone: boolean = false;
let defaultOpenKeysBeforeCollapse: string[] = [];

const PageSidebar: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const userRole = useSelector(getUserRole);

    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>(
        []
    );
    const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([]);

    const [menuItemComponents, setMenuItemComponents] = useState<any[]>([]);

    const [menuHeight, setMenuHeight] = useState<number>(
        window.innerHeight - 112
    );

    /**
     * Function called for changing the url address viewed by user.
     * @param route - path/url to go to
     */
    const changeRoute = (route: string) => {
        props.history.push(route);
    };

    /**
     * Function for populating the menu item title.
     * Includes the icon for the menu item if required.
     */
    const populateSpanTitle = useCallback(
        (name: string, icon?: string[]) => (
            <>
                {icon && <FontAwesome icon={icon} className="mr-10" />}
                <span className="sm-item-title">{name}</span>
            </>
        ),
        []
    );

    /**
     * Function for populating the menu items.
     */
    const populateMenuItems = useCallback(
        (items: [any]) => {
            const menuItemComponents = map(items, (item: any) => {
                if (
                    isEmpty(item.allowedRoles) ||
                    includes(item.allowedRoles, userRole)
                ) {
                    const fullRoute: string = `${props.match.path}${item.route}`;
                    if (item.children) {
                        return (
                            <SubMenu
                                key={fullRoute}
                                title={populateSpanTitle(item.name, item.icon)}
                            >
                                {populateMenuItems(item.children)}
                            </SubMenu>
                        );
                    } else {
                        return (
                            <Menu.Item key={fullRoute}>
                                {populateSpanTitle(item.name, item.icon)}
                            </Menu.Item>
                        );
                    }
                }
            });

            return menuItemComponents;
        },
        [populateSpanTitle, props.match.path, userRole]
    );

    /**
     * Function for getting the menu item components and save it to state.
     */
    const getMenuItemComponents = useCallback(async () => {
        const menuItemComponentsPopulated = await populateMenuItems(
            props.menuItems
        );

        if (!isEmpty(menuItemComponentsPopulated)) {
            if (unmounted) return;
            setMenuItemComponents(menuItemComponentsPopulated);
        }
    }, [populateMenuItems, props.menuItems]);

    /**
     * Function triggered when menu is selected.
     * @param param0
     */
    const onMenuSelect = ({ selectedKeys }: { selectedKeys: string[] }) => {
        currentSelectedKeys = selectedKeys;
        changeRoute(selectedKeys[0]);
        // setDefaultSelectedKeys(selectedKeys);
    };

    /**
     * Function called when a submenu is opened/closed.
     * @param openKeys
     */
    const onSubmenuOpenChange = (openKeys: string[]) => {
        currentOpenKeys = openKeys;
        setDefaultOpenKeys(openKeys);
    };

    /**
     * Function that is called whenever the sidebar is collapsed or expanded.
     */
    const toggleMenuCollapsed = () => {
        if (menuCollapsed) {
            onSubmenuOpenChange(defaultOpenKeysBeforeCollapse);
        } else {
            defaultOpenKeysBeforeCollapse = defaultOpenKeys;
            onSubmenuOpenChange([]);
        }

        setMenuCollapsed((prevState: boolean) => !prevState);
    };

    const setMenuCollapsedRedux = () => {
        dispatch(setMenuCollapsedAction(menuCollapsed));
    };

    useEffect(setMenuCollapsedRedux, [menuCollapsed]);

    /**
     * Function that calls a function to prepare the menu item components.
     */
    const initiateGetMenuItemComponents = () => {
        if (!isNil(userRole)) {
            getMenuItemComponents();
        }
    };

    useEffect(initiateGetMenuItemComponents, [userRole, props.menuItems]);

    /**
     * Function that is called when the route is changed.
     * Responsible for collapsing/expanding submenu items and setting the highlighted menu item.
     * @param items - menu items
     */
    const updateOpenAndActiveKeysOnRouteChange = async (
        items: [] = props.menuItems
    ) => {
        await forEach(items, (item: any) => {
            const fullRoute: string = `${props.match.path}${item.route}`;
            if (includes(props.location.pathname, fullRoute)) {
                if (!includes(currentSelectedKeys, fullRoute)) {
                    currentSelectedKeys = [...currentSelectedKeys, fullRoute];
                }
            }

            if (item.children) {
                updateOpenAndActiveKeysOnRouteChange(item.children);
                if (includes(props.location.pathname, fullRoute)) {
                    if (!includes(currentOpenKeys, fullRoute)) {
                        currentOpenKeys = [...currentOpenKeys, fullRoute];
                        defaultOpenKeysBeforeCollapse = currentOpenKeys;
                    }
                }
            }
        });
    };

    /**
     * Function for initializing open and active keys for menu & submenu items.
     */
    const initiateRouteOpenAndActiveKeys = () => {
        currentSelectedKeys = [];
        updateOpenAndActiveKeysOnRouteChange();
        if (currentSelectedKeys.length > 1) {
            delete currentSelectedKeys[0];
        }

        setDefaultSelectedKeys(currentSelectedKeys);
        if (!menuCollapsed) setDefaultOpenKeys(currentOpenKeys);
    };

    useEffect(initiateRouteOpenAndActiveKeys, [props.location.pathname]);

    // on Unmount
    useEffect(() => {
        unmounted = false;
        return () => {
            unmounted = true;
            currentOpenKeys = [];
            currentSelectedKeys = [];
        };
    }, []);

    /**
     * Function called when window resize is triggered.
     */
    const checkWindowSize = () => {
        const isCollapsed = getIfIsLg();

        if (isCollapsed) {
            onSubmenuOpenChange([]);
        } else {
            if (intialWindowSizeLoadDone) {
                onSubmenuOpenChange(compact(defaultOpenKeysBeforeCollapse));
            } else {
                intialWindowSizeLoadDone = true;
            }
        }

        setMenuCollapsed(isCollapsed);
        setMenuHeight(window.innerHeight - 112);
    };

    const hideShowSideBar = useCallback(debounce(checkWindowSize, 400), []);

    /**
     * Function that adds a listener for window resize and binds it to a function.
     */
    const resizeWindowInitializer = () => {
        window.addEventListener('resize', hideShowSideBar);
    };
    useLayoutEffect(resizeWindowInitializer, []);

    useLayoutEffect(hideShowSideBar, []);

    return (
        <Sider
            className="sider-component"
            trigger={
                <div style={{ padding: '0px 20px' }}>
                    <Icon
                        className="ant-btn-link secondary-contrast-color"
                        type={menuCollapsed ? 'menu-unfold' : 'menu-fold'}
                    />
                </div>
            }
            collapsible
            collapsed={menuCollapsed}
            onCollapse={toggleMenuCollapsed}
            width={220}
        >
            <Menu
                mode="inline"
                selectedKeys={defaultSelectedKeys}
                openKeys={defaultOpenKeys}
                style={{
                    height: menuHeight,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}
                onSelect={onMenuSelect}
                onOpenChange={onSubmenuOpenChange}
                className="menu-custom-hide-icon"
            >
                {menuItemComponents}
            </Menu>
        </Sider>
    );
};

export default memo(withRouter(PageSidebar));
