import API, { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { TASKS_PAGE,DETAILS_TAB } from '../../config/tableAndPageConstants';
// import { ApplicationState } from '..';
import queries from '../../graphql/queries.graphql';
import {
    checkShouldRequestRefetch,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    getTaskDataErrorAction,
    getTaskDataSuccessAction,
    getTasksErrorAction,
    getTasksRequestAction,
    getTasksSuccessAction,
    getTaskTicketInvoicesSuccessAction,
    getTaskHistoryNotificationInvoicesErrorAction,
    notifyCustomersResponseAction,
    setSelectedTaskIdSuccessAction,
} from './actions';
import { TasksActionTypes } from './types';

export const getTaskData = (state: ApplicationState) => state.tasks.activeData;

export const getSelectedTaskId = (state: ApplicationState) =>
    state.tasks.activeData.selectedId;

let refetchCount = 0;
/**
 * Function that fetches the tasks list - api connection.
 * @param param0
 */
function* handleGetTasksRequest({ payload }: any) {
    const errorMessage = 'Error fetching task list. Please try again later.';
    try {
        const {
            filters,
            // taskState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            excludeTasks,
            isUsingCloudImportType
        } = payload;
        // To call async functions, use redux-saga's `call()`.
        const sortFields = populateSortFieldsParamSortParams(sortBy);

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task'
        );

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASKS_FOR_EXTERNAL_USER, {
                ...cleanFilters,
                ...sortFields,
                ExcludeTasks: excludeTasks,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * TASKS_PAGE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
            })
        );

        const { Tasks } = get(res.data, 'GetTasksForExternalUser');
        if (Tasks) {
            const responsePayload = {
                data: Tasks,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Tasks.length < pageSize) &&
                        !(pageSize < TASKS_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getTasksSuccessAction(responsePayload));
        } else {
            yield put(getTasksErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTasksRequestAction(payload));
        } else {
            yield put(getTasksErrorAction([errorMessage]));
        }
    }
}

/**
 * Function that calls the API for getting the tasks details based on the given Id.
 * @param param0
 */
function* handleGetTaskDataRequest({ payload: sagaPayload }: any) {
    const { taskId } = sagaPayload;
    const errorMessage = 'Error fetching task details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASKS_FOR_EXTERNAL_USER, {
                TaskIds: [taskId],
                ExcludeTasks: false,
                PageSize: 1,
                Skip: 0,
                IsComplete: true,
            })
        );

        const Task = get(get(res.data, 'GetTasksForExternalUser.Tasks'), 0);
        if (Task) {
            const responsePayload = {
                record: Task,
            };

            yield put(getTaskDataSuccessAction(responsePayload));
        } else {
            yield put(getTaskDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskDataErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the ticket invoices list.
 * @param param0
 */
 function* handleGetTaskTicketInvoicesRequest({ payload }: any) {
    const errorMessage = `Error fetching notification's invoice list. Please try again later.`;
    try {
        const {
            filters,
            companyId,
            sortBy,
            sortAscending,
            invoiceState,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICES_FOR_EXTERNAL_USER, {
                ...cleanFilters,
                CompanyId: companyId,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.INVOICE_LIST.pageSize,
            })
        );

        const { Invoices } = get(res.data, 'GetInvoicesForExternalUser');
        if (Invoices) {
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Invoices.length < pageSize) &&
                        !(pageSize < DETAILS_TAB.INVOICE_LIST.pageSize),
                },
            };

            yield put(getTaskTicketInvoicesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(
            getTaskHistoryNotificationInvoicesErrorAction([errorMessage])
        );
    }
}
/**
 * Common function for preparing the sort values inside the payload needed for connecting to API.
 * @param sortBy
 */
export const populateSortFieldsParamSortParams = (sortBy: string) => {
    let sortFields: DynamicObject = {};

    sortFields = { SortField: sortBy, ThenSortField: '' };

    if (sortBy === 'Type') {
        sortFields.SortField = 'Automation';
        sortFields.ThenSortField = 'Type';
    }

    return sortFields;
};

/**
 * Function for checking the ready state of tasks selected to enable/disable the `Action` button in
 * Active tasks page.
 * @param param0
 */
function* handleCheckTasksReadyStateRequest({ payload }: any) {
    const {
        filters,
        sortBy,
        sortAscending,
        pageSize,
        currentPage,
        taskIds,
        excludeTasks,
        callback,
    } = payload;
    // To call async functions, use redux-saga's `call()`.
    const sortFields = populateSortFieldsParamSortParams(sortBy);

    const cleanFilters = removeAppliedFiltersForApiRequest(
        filters,
        true,
        'task'
    );
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.CHECK_TASKS_ACTION_STATE, {
                ...cleanFilters,
                ...sortFields,
                TaskIds: taskIds,
                ExcludeTasks: excludeTasks,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * TASKS_PAGE.pageSize,
            })
        );

        const tasksActionState = get(res.data, 'TasksActionState');

        if (callback) callback(tasksActionState);
    } catch (err) {
        yield put(notifyCustomersResponseAction());
        if (callback) {
            callback(false);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when resolving a ticket.
 * @param param0
 */
function* handleResolveQueryRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/ticket',
            {
                body: payload,
            }
        );
        const { TaskEditAction } = res;
        let successMessages: any = [];
        if (TaskEditAction) {
            successMessages.push(`Ticket has been resolved successfully!`);
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for escalating the selected task items (in Active tasks page) to collections.
 * @param param0
 */
function* handleEscalateToCollections({ payload: sagaPayload }: any) {
    const { filter, taskIds, excludeTasks, usingCustomerWorkflow, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'task'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const payload = {
        TaskManagementFilter: {
            ...cleanFilters,
            TaskIds: taskIds,
            ExcludeTasks: excludeTasks,
        },
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/collection/escalate',
            {
                body: payload,
            }
        );
        const { TaskEditAction } = res;
        let successMessages: any = [];
        if (TaskEditAction) {
            const itemText = usingCustomerWorkflow ? 'Customer' : 'Invoice';
            successMessages.push(
                `${itemText}(s) escalated to collections successfully!`
            );
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when proceeding with notify customers for communication tasks.
 * @param param0
 */
function* handleNotifyCustomersRequest({ payload: sagaPayload }: any) {
    const { filter, taskIds, excludeTasks, callback } = sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'task'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const payload = {
        TaskManagementFilter: {
            ...cleanFilters,
            TaskIds: taskIds,
            ExcludeTasks: excludeTasks,
        },
    };

    try {
        // To call async functions, use redux-saga's `call()`.
        yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/automated-communication/send',
            {
                body: payload,
            }
        );

        yield put(notifyCustomersResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
    } catch (err) {
        yield put(notifyCustomersResponseAction());
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the `Action` options on resolving ticket panel.
 * @param param0
 */
function* handleGetResolveTicketOptionsRequest({ payload: sagaPayload }: any) {
    const { workflowId, stateName, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TRIGGERS_FOR_WORKFLOW_STATE, {
                WorkflowId: workflowId,
                StateName: stateName,
            })
        );

        const { WorkflowTransitions: triggerOptions } = get(
            res,
            'data.GetTriggersForWorkflowState'
        );

        if (callback) callback(triggerOptions);
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function that sets the selected tasks id in the redux state for reference.
 * @param param0
 */
function* handleSetSelectedTaskIdRequest({ payload }: any) {
    const { taskId, callback } = payload;
    yield put(setSelectedTaskIdSuccessAction(taskId));
    callback();
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetTasksRequest() {
    yield takeLatest(TasksActionTypes.GET_TASKS_REQUEST, handleGetTasksRequest);
}

function* watchGetTaskDataRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_DATA_REQUEST,
        handleGetTaskDataRequest
    );
}

function* watchSetSelectedTaskIdRequest() {
    yield takeLatest(
        TasksActionTypes.SET_TASK_SELECTED_ID_REQUEST,
        handleSetSelectedTaskIdRequest
    );
}

function* watchCheckTasksReadyStateRequest() {
    yield takeLatest(
        TasksActionTypes.CHECK_TASKS_READY_STATE,
        handleCheckTasksReadyStateRequest
    );
}

function* watchGetTaskTicketInvoicesRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_TICKET_INVOICES_REQUEST,
        handleGetTaskTicketInvoicesRequest
    );
}

function* watchResolveQueryRequest() {
    yield takeLatest(
        TasksActionTypes.RESOLVE_QUERY_REQUEST,
        handleResolveQueryRequest
    );
}

function* watchEscalateToCollections() {
    yield takeLatest(
        TasksActionTypes.ESCALATE_TO_COLLECTIONS,
        handleEscalateToCollections
    );
}

function* watchNotifyCustomersRequest() {
    yield takeLatest(
        TasksActionTypes.NOTIFY_CUSTOMERS_REQUEST,
        handleNotifyCustomersRequest
    );
}

function* watchGetResolveTicketOptionsRequest() {
    yield takeLatest(
        TasksActionTypes.GET_RESOLVE_TICKET_OPTIONS_REQUEST,
        handleGetResolveTicketOptionsRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* tasksSaga() {
    yield all([
        fork(watchGetTasksRequest),
        fork(watchGetTaskDataRequest),
        fork(watchSetSelectedTaskIdRequest),
        fork(watchCheckTasksReadyStateRequest),
        fork(watchGetTaskTicketInvoicesRequest),
        fork(watchResolveQueryRequest),
        fork(watchEscalateToCollections),
        fork(watchNotifyCustomersRequest),
        fork(watchGetResolveTicketOptionsRequest),
    ]);
}

export default tasksSaga;
