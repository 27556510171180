/**
 * Component for the page header. The one at the top that contains the Company logo, company dropdown and user dropdown.
 */

import {
    Avatar,
    Button,
    Col,
    Dropdown,
    Icon,
    Layout,
    Menu,
    Modal,
    Row,
    Spin,
    Switch,
} from 'antd';
import { ClickParam } from 'antd/lib/menu';
import {
    compact,
    debounce,
    get,
    isEmpty,
    map,
    truncate,
    upperCase,
} from 'lodash';
import React, {
    lazy,
    Suspense,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Environments } from '../../AmplifyConfig';
import {
    SYSTEM_ENDPOINT_DEV,
    SYSTEM_ENDPOINT_NONPROD,
    SYSTEM_ENDPOINT_PROD,
    SYSTEM_ENDPOINT_TEST,
    SYSTEM_ENDPOINT_UAT,
} from '../../config/config';
import {
    EmailAttribute,
    FamilyNameAttribute,
    GivenNameAttribute,
} from '../../constants/authUserAttributes';
import { ApplicationState } from '../../store';
import { SystemConfiguration } from '../../store/companies/types';
import {
    getUserWeeklyNotificationsStatusRequestAction,
    updateUserWeeklyNotificationsRequestAction,
} from '../../store/users/actions';
import { getCurrentUser } from '../../store/users/sagas';
import {
    getAssetsPath,
    getCurrentBuildEnvironment,
    getIfIsLg,
    getNameInitials,
    stringToHex,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import FontAwesome from '../common/FontAwesome';
import ModalWithSpinner from '../common/ModalWithSpinner';

const { Header } = Layout;

const assetsPath = getAssetsPath();
const iodmLogo = `${assetsPath}/logo.png`;

const ImageWithLoading = lazy(() => import('../common/ImageWithLoading'));
interface IProps {
    logoutUser: () => void;
    openNotificationsMenu: (count?: number) => void;
    pageLayoutLoading: boolean;
    history: any;
}
const PageHeader: React.FC<IProps> = (props: IProps) => {
    const currentUser = useSelector(getCurrentUser);
    const bannerRef: any = useRef(null);
    const dispatch = useDispatch();

    const [bannerData, setBannerData] = useState<{
        fullEnvironmentBanner: string;
        environmentBanner: string;
        showBannerTooltip: boolean;
        bannerMarginLeft: number;
    }>({
        fullEnvironmentBanner: '',
        environmentBanner: '',
        showBannerTooltip: false,
        bannerMarginLeft: 0,
    });

    const [weeklyNotificationsState, setWeeklyNotificationsState] = useState<{
        loading: boolean;
        fetchLoading: boolean;
        enabled: boolean;
    }>({
        loading: false,
        fetchLoading: false,
        enabled: false,
    });

    const systemConfiguration: SystemConfiguration = useSelector(
        (state: ApplicationState) => state.companies.systemConfiguration
    );

    const username = get(currentUser, GivenNameAttribute);

    const fullName = `${get(currentUser, GivenNameAttribute)} ${get(
        currentUser,
        FamilyNameAttribute
    )}`;

    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

    const getRedirectUrl = (env: string) => {
        if (env === Environments.DEV) {
            if (
                window.location.hostname === 'localhost' ||
                window.location.hostname === '127.0.0.1'
            ) {
                return `http://${window.location.hostname}:3000`;
            } else {
                return `https://${SYSTEM_ENDPOINT_DEV}`;
            }
        } else if (env === Environments.TEST) {
            return `https://${SYSTEM_ENDPOINT_TEST}`;
        } else if (env === Environments.UAT) {
            return `https://${SYSTEM_ENDPOINT_UAT}`;
        } else if (env === Environments.NONPROD) {
            return `https://${SYSTEM_ENDPOINT_NONPROD}`;
        } else if (env === Environments.PROD) {
            return `https://${SYSTEM_ENDPOINT_PROD}`;
        }
    };

    const switchToIodmConnect = () => {
        const buildEnvironment = getCurrentBuildEnvironment();
        const redirectUrl = getRedirectUrl(buildEnvironment);
        if (redirectUrl) window.location.replace(redirectUrl);
    };

    /**
     * Function called on menu item clicked. Will control the visibility of the dropdown.
     * @param e
     */
    const handleMenuClick = (e: ClickParam) => {
        const isWeeklyNotificationsClicked = e.key === 'weekly-notifications';
        if (isWeeklyNotificationsClicked) {
            toggleWeeklyNotifications(!weeklyNotificationsState.enabled);
        }
        setDropdownVisible(isWeeklyNotificationsClicked);
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param weeklyNotificationsStateObject
     */
    const updateWeeklyNotificationsStateObject = (
        weeklyNotificationsStateObject: DynamicObject
    ) => {
        setWeeklyNotificationsState({
            ...weeklyNotificationsState,
            ...weeklyNotificationsStateObject,
        });
    };

    const getUserWeeklyNotificationsStatus = () => {
        updateWeeklyNotificationsStateObject({
            fetchLoading: true,
        });
        dispatch(
            getUserWeeklyNotificationsStatusRequestAction(
                (res: DynamicObject) => {
                    const isSuccessful = get(res, 'IsSuccess');
                    if (isSuccessful) {
                        const isBlacklisted = get(res, 'IsBlacklisted');
                        updateWeeklyNotificationsStateObject({
                            fetchLoading: false,
                            enabled: !isBlacklisted,
                        });
                    } else {
                        updateWeeklyNotificationsStateObject({
                            fetchLoading: false,
                        });
                        const messages: string[] = compact(
                            get(res, 'Messages', [])
                        );
                        let errorMessageContent:
                            | string
                            | JSX.Element[] = `Failed to fetch user weekly reminder status!`;
                        if (!isEmpty(messages)) {
                            errorMessageContent = map(
                                messages,
                                (error: string, index: number) => (
                                    <div key={index}>{error}</div>
                                )
                            );
                        }

                        Modal.error({
                            title: 'Error',
                            content: errorMessageContent,
                        });
                    }
                }
            )
        );
    };

    useEffect(getUserWeeklyNotificationsStatus, []);

    /**
     * Function called when weekly notifications switch is toggled.
     * @param status
     */
    const toggleWeeklyNotifications = (status: boolean) => {
        updateWeeklyNotificationsStateObject({
            loading: true,
        });
        const statusLabel = status ? 'on' : 'off';
        dispatch(
            updateUserWeeklyNotificationsRequestAction(
                status,
                ({ IsSuccess, Messages }: DynamicObject) => {
                    if (IsSuccess) {
                        updateWeeklyNotificationsStateObject({
                            loading: false,
                            enabled: !weeklyNotificationsState.enabled,
                        });
                        Modal.success({
                            title: 'Success',
                            content: `Weekly reminder turned ${statusLabel} successfully!`,
                            onOk: () => {
                                // can call API here for refetching but not necessary
                                setDropdownVisible(false);
                                getUserWeeklyNotificationsStatus();
                            },
                        });
                    } else {
                        updateWeeklyNotificationsStateObject({
                            loading: false,
                        });
                        let errorMessageContent:
                            | string
                            | JSX.Element[] = `Failed to turn ${statusLabel} weekly reminder!`;
                        if (!isEmpty(Messages)) {
                            errorMessageContent = map(
                                Messages,
                                (error: string, index: number) => (
                                    <div key={index}>{error}</div>
                                )
                            );
                        }

                        Modal.error({
                            title: 'Error',
                            content: errorMessageContent,
                            onOk: () => {
                                setDropdownVisible(true);
                            },
                        });
                    }
                }
            )
        );
    };

    /**
     * Function to populate the user dropdown overlay.
     */
    const populateUserDropdownMenuOverlay = () => (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="user-profile">
                <div className="center-flex-sb">
                    <div>{populateUserAvatar('large')}</div>
                    <div className="pa-10 mw-300px">
                        <div className="ws-nm">{fullName}</div>
                        <div className="ws-nm">
                            {get(currentUser, EmailAttribute)}
                        </div>
                    </div>
                </div>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="weekly-notifications">
                <span className="center-flex">
                    <FontAwesome
                        className="mr-10 fs-20"
                        icon={['far', 'bell']}
                    />{' '}
                    Weekly reminder:
                    <span className="weekly-notifications-switch ml-8">
                        <Switch
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            checked={weeklyNotificationsState.enabled}
                            loading={
                                weeklyNotificationsState.fetchLoading ||
                                weeklyNotificationsState.loading
                            }
                        />
                    </span>
                </span>
            </Menu.Item>
            <Menu.Item key="switch-iodm-connect" onClick={switchToIodmConnect}>
                <span className="center-flex">
                    <FontAwesome
                        className="mr-10 fs-20"
                        icon={['fas', 'random']}
                    />{' '}
                    Switch to IODM connect
                </span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" onClick={handleLogout}>
                <div className="mb-20">
                    <div className="center-flex">
                        <FontAwesome
                            className="mr-10 fs-20"
                            icon={['fas', 'sign-out-alt']}
                        />{' '}
                        Sign Out
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    );

    const usedAvatarColor: any = stringToHex(fullName);

    /**
     * Function to populate the user avatar icon.
     * @param size
     */
    const populateUserAvatar = (
        size: number | 'default' | 'small' | 'large' | undefined = 'default'
    ) => (
        <Avatar size={size} style={{ backgroundColor: usedAvatarColor }}>
            {getNameInitials(fullName)}
        </Avatar>
    );

    /**
     * Function called when user clicks on logout button in user popover.
     */
    const handleLogout = () => {
        props.logoutUser();
    };

    const isLiveMode = get(systemConfiguration, 'LiveMode');

    /**
     * Function that is called upon window resize.
     */
    const checkWindowSize = () => {
        initializeBannerData();
    };

    /**
     * Function that will initialize the banner data (for environment) needed to adjust the banner size and location (if present).
     */
    const initializeBannerData = () => {
        if (isLiveMode) return;

        const isLg = getIfIsLg();
        const environmentMaxLength = 14;
        const fullEnvironmentBanner = upperCase(
            get(systemConfiguration, 'Environment')
        );
        const environmentBanner = isLg
            ? truncate(fullEnvironmentBanner, {
                  length: environmentMaxLength,
              })
            : fullEnvironmentBanner;

        const showBannerTooltip =
            fullEnvironmentBanner.length > environmentMaxLength;

        const bannerSpan = bannerRef.current
            ? bannerRef.current.clientWidth
            : 147;

        const bannerMarginLeft = bannerSpan + (isLg ? 15 : 33);

        setBannerData({
            fullEnvironmentBanner,
            environmentBanner,
            showBannerTooltip,
            bannerMarginLeft,
        });
    };

    /**
     * Callback function that will be called whenever a window resize is triggered.
     * Applies debounce to keep a succeeding function from being called when resize is trigger in
     * a short span of time.
     */
    const resizeWindowHandler = useCallback(debounce(checkWindowSize, 400), []);

    useLayoutEffect(initializeBannerData, [
        bannerRef.current,
        bannerData.environmentBanner,
        isLiveMode,
    ]);

    /**
     * Function that adds a listener for window resize and binds it to a function.
     */
    const resizeWindowInitializer = () => {
        window.addEventListener('resize', resizeWindowHandler);
    };
    useLayoutEffect(resizeWindowInitializer, []);

    /**
     * Function for handling the visibility of the dropdown.
     * @param flag
     */
    const handleDropdownVisibilityChange = (flag: boolean) => {
        setDropdownVisible(flag);
    };

    /**
     * Function that controls the display of weekly notifications loading modal when it's toggled.
     */
    const populateWeeklyNotificationsLoadingModal = () => {
        const { loading, enabled } = weeklyNotificationsState;
        if (!loading) return null;
        const weeklyNotificationStatusLabel = enabled ? 'off' : 'on';
        return (
            <ModalWithSpinner
                modalTitle={`Turning ${weeklyNotificationStatusLabel} weekly reminder`}
                modalVisible={loading}
                displayMessage={`Please wait while turning ${weeklyNotificationStatusLabel} your weekly reminder . . .`}
            />
        );
    };

    const elementsCursor = 'cursor-p';
    return (
        <Header className="header">
            <Row>
                <Col
                    xs={{ span: 6, offset: 0 }}
                    sm={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                >
                    <div className="logo">
                        <Suspense fallback={<Spin />}>
                            <ImageWithLoading
                                src={iodmLogo}
                                alt="IODM Logo"
                                style={{ width: '60px', height: 'auto' }}
                            />
                        </Suspense>
                    </div>
                </Col>
                <Col
                    xs={{ span: 12, offset: 0 }}
                    sm={{ span: 12, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    style={{ textAlign: 'center' }}
                >
                    <div className="secondary-contrast-color task-portal">
                        Task Portal
                    </div>
                </Col>
                <Col
                    className="ta-right"
                    xs={{ span: 6, offset: 0 }}
                    sm={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                >
                    <span className={elementsCursor}>
                        <Dropdown
                            trigger={['click']}
                            overlay={populateUserDropdownMenuOverlay()}
                            visible={dropdownVisible}
                            onVisibleChange={handleDropdownVisibilityChange}
                            // disabled={isEdittingDashboard}
                        >
                            <span>
                                <Button
                                    type="link"
                                    className="pa-0 mr-10 header-username secondary-contrast-color"
                                >
                                    {username} <Icon type="down" />
                                </Button>
                                {/* <Badge
                                    className={elementsCursor}
                                    count={notificationsCount}
                                    offset={[3, 6]}
                                > */}
                                {populateUserAvatar()}
                                {/* </Badge> */}
                            </span>
                        </Dropdown>
                    </span>
                    {populateWeeklyNotificationsLoadingModal()}
                </Col>
            </Row>
        </Header>
    );
};

export default withRouter(PageHeader);
