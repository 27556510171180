import API, { graphqlOperation } from '@aws-amplify/api';
import { forEach, get, includes, orderBy } from 'lodash';
import { all, call, fork, select, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import { API_NAME } from '../../config/config';
import queries from '../../graphql/queries.graphql';
import { DynamicObject } from '../../utils/commonInterfaces';
import { getUserSelectedCompany } from '../companies/sagas';
import { CompanyCustomFieldConfigure } from '../companies/types';
import { CommonFiltersActionTypes } from './types';

export const getDefaultPageViewValue = (state: ApplicationState) =>
    state.app.defaultPageViewValue;

export const getIsAttachmentEditingActive = (state: ApplicationState) =>
    state.app.attachmentEditing;

export const getLocale = (state: ApplicationState) => state.app.locale;

/**
 * Function called for saving the applied filters view to db.
 */
function* handleSaveAppliedFiltersView({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/pageView/view',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for deleting the applied filters view from db.
 */
function* handleDeleteAppliedFiltersView({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/pageView/view',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for fetching the Page Views list from the api.
 */
function* handleFetchPageAppliedFiltersViewListRequestAction({
    payload: sagaPayload,
}: any) {
    const { pageName, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAGE_VIEWS, {
                PageName: pageName,
            })
        );

        const { Views: PageViews } = get(res.data, 'GetPageViews');
        if (PageViews) {
            if (callback) callback(PageViews);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        if (callback) callback([]);
    }
}

/**
 * Function called for starting the data extraction for the items needed in excel file.
 */
function* handleDownloadToExcel({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        yield call([API, 'post'], API_NAME, '/report/excel', {
            body: payload,
        });

        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
    }
}

/**
 * Function called for getting the presigned URL which then is used
 * for downloading the excel report stored in S3.
 */
function* handleGetPresignedUrlForReportReady({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'get'],
            API_NAME,
            `/report/excel?ReportJobId=${get(payload, 'EntityKey')}`,
            {}
        );

        if (callback) {
            res.IsSuccess = true;
            callback(res);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
    }
}

/**
 * Function called for getting the custom fields filter list for a specific page.
 */
function* handleGetCustomFieldsFilterListByTypes({
    payload: sagaPayload,
}: any) {
    const { customFieldTypes, callback } = sagaPayload;

    try {
        const selectedUserCompany: DynamicObject = yield select(
            getUserSelectedCompany
        );

        const companyCustomFields = get(
            selectedUserCompany,
            'Company.CustomFieldsConfigure'
        );

        let customFieldsByType: CompanyCustomFieldConfigure[] = [];
        forEach(
            companyCustomFields,
            (customField: CompanyCustomFieldConfigure) => {
                if (includes(customFieldTypes, customField.Type)) {
                    customFieldsByType.push(customField);
                }
            }
        );

        const customFieldsByNumberSorted = orderBy(
            customFieldsByType,
            ['Number'],
            ['asc']
        );

        var sortedCustomFieldsByType = orderBy(
            customFieldsByNumberSorted,
            function (item) {
                return customFieldTypes.indexOf(item.Type);
            }
        );

        const res: DynamicObject = {
            CustomFields: sortedCustomFieldsByType,
        };

        if (callback) {
            res.IsSuccess = true;
            callback(res);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
    }
}

/**
 * Function called for getting the presigned url for uploading an attachment.
 */
function* handleGetAttachmentPresignedUrl({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/document/attachtoresource',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for deleting an attachment.
 */
function* handleDeleteAttachment({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/document/attachtoresource',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function* watchSaveAppliedFiltersView() {
    yield takeLatest(
        CommonFiltersActionTypes.SAVE_APPLIED_FILTERS_VIEW,
        handleSaveAppliedFiltersView
    );
}

function* watchDeleteAppliedFiltersView() {
    yield takeLatest(
        CommonFiltersActionTypes.DELETE_APPLIED_FILTERS_VIEW,
        handleDeleteAppliedFiltersView
    );
}

function* watchFetchPageAppliedFiltersViewListRequest() {
    yield takeLatest(
        CommonFiltersActionTypes.FETCH_PAGE_APPLIED_FILTERS_VIEW_LIST_REQUEST,
        handleFetchPageAppliedFiltersViewListRequestAction
    );
}

function* watchDownloadToExcel() {
    yield takeLatest(
        CommonFiltersActionTypes.DOWNLOAD_TO_EXCEL,
        handleDownloadToExcel
    );
}

function* watchGetPresignedUrlForReportReady() {
    yield takeLatest(
        CommonFiltersActionTypes.GET_PRESIGNED_URL_FOR_REPORT_READY,
        handleGetPresignedUrlForReportReady
    );
}

function* watchGetCustomFieldsFilterListByTypes() {
    yield takeLatest(
        CommonFiltersActionTypes.GET_CUSTOM_FIELDS_FILTER_LIST,
        handleGetCustomFieldsFilterListByTypes
    );
}

function* watchGetAttachmentPresignedUrl() {
    yield takeLatest(
        CommonFiltersActionTypes.GET_ATTACHMENT_PRESIGNED_URL,
        handleGetAttachmentPresignedUrl
    );
}

function* watchDeleteAttachment() {
    yield takeLatest(
        CommonFiltersActionTypes.DELETE_ATTACHMENT,
        handleDeleteAttachment
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* commonSaga() {
    yield all([
        fork(watchSaveAppliedFiltersView),
        fork(watchDeleteAppliedFiltersView),
        fork(watchFetchPageAppliedFiltersViewListRequest),
        fork(watchDownloadToExcel),
        fork(watchGetPresignedUrlForReportReady),
        fork(watchGetCustomFieldsFilterListByTypes),
        fork(watchGetAttachmentPresignedUrl),
        fork(watchDeleteAttachment),
    ]);
}

export default commonSaga;
