import { action } from 'typesafe-actions';
import { UsersActionTypes } from './types';

/**
 * Function for getting the users weekly notifications status.
 */
export const getUserWeeklyNotificationsStatusRequestAction = (
    callback: (response: any) => void
) =>
    action(
        UsersActionTypes.GET_USER_WEEKLY_NOTIFICATION_STATUS_REQUEST,
        callback
    );

/**
 * Action for calling the api to update the user's weekly notifications status
 */
export const updateUserWeeklyNotificationsRequestAction = (
    status: boolean,
    callback?: (response: any) => void
) =>
    action(UsersActionTypes.UPDATE_USER_WEEKLY_NOTIFICATION_REQUEST, {
        status,
        callback,
    });
