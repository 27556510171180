/**
 * Component for handling accounting system selections.
 */

import { get } from 'lodash';
import React from 'react';
import { companyImportTypes } from '../../constants/settings';

export const withAccountingSystemHandler = (WrappedComponent: any) => {
    const AccountingSystemHandler: React.FC<any> = (props: any) => {
        const importType = get(props.Company, 'ImportType');
        
        const isUsingCloudImportType = importType === companyImportTypes.CLOUD;

        return (
            <WrappedComponent
                {...props}
                isUsingCloudImportType={isUsingCloudImportType}
            />
        );
    };

    return AccountingSystemHandler;
};
