// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import {
    faStreetView,
    faAmbulance,
    faPlusSquare,
    faClipboardList,
    faSignInAlt,
    faSignOutAlt,
    //End here
    faExclamationTriangle,
    faArrowLeft,
    faAddressCard,
    faQuestionCircle,
    faTachometerAlt,
    faUsers,
    faChartLine,
    faHome,
    faEnvelope,
    faMobileAlt,
    faSortDown,
    faTimesCircle,
    faTasks,
    faSync,
    faHandHoldingUsd,
    faWallet,
    faPiggyBank,
    faCogs,
    faRetweet,
    faFileAlt,
    faComments,
    faAddressBook,
    faFileInvoice,
    faList,
    faFilter,
    faSortAmountDown,
    faSortAmountUp,
    faSortAmountUpAlt,
    faCloudDownloadAlt,
    faEnvelopeOpen,
    faComment,
    faFilePdf,
    faFileExcel,
    faEllipsisH,
    faSatelliteDish,
    faCheck,
    faCheckCircle,
    faRobot,
    faCheckDouble,
    faUserTag,
    faInfoCircle,
    faUserEdit,
    faUserPlus,
    faEdit,
    faForward,
    faSignal,
    faPhoneAlt,
    faPhoneVolume,
    faUser,
    faSpinner,
    faCrown,
    faMoneyBillAlt,
    faInfo,
    faHandshake,
    faUndo,
    faUserLock,
    faCopy,
    faFile,
    faPlus,
    faFileInvoiceDollar,
    faLayerGroup,
    faFileDownload,
    faMinusSquare,
    faSave,
    faChartPie,
    faTable,
    faSquare,
    faCog,
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faReceipt,
    faExternalLinkAlt,
    faMoneyCheckAlt,
    faCreditCard,
    faDonate,
    faRandom,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFrownOpen,
    faPlayCircle,
    faQuestionCircle as farFaQuestionCircle,
    faCircle,
    faSmile,
    faFrown,
    faChartBar,
    faTimesCircle as farFaTimesCircle,
    faBell,
} from '@fortawesome/free-regular-svg-icons';

import {
    faCcMastercard,
    faCcVisa,
    faCcAmex,
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faStreetView,
    faAmbulance,
    faPlusSquare,
    faClipboardList,
    faSignInAlt,
    faSignOutAlt,
    //End here
    faExclamationTriangle,
    faArrowLeft,
    faAddressCard,
    faQuestionCircle,
    faTachometerAlt,
    faUsers,
    faChartLine,
    faHome,
    faEnvelope,
    faMobileAlt,
    faSortDown,
    faTimesCircle,
    faTasks,
    faSync,
    faHandHoldingUsd,
    faWallet,
    faPiggyBank,
    faCogs,
    faRetweet,
    faFileAlt,
    faComments,
    faAddressBook,
    faFileInvoice,
    faList,
    faFilter,
    faSortAmountDown,
    faSortAmountUp,
    faSortAmountUpAlt,
    faCloudDownloadAlt,
    faEnvelopeOpen,
    faComment,
    faFilePdf,
    faFileExcel,
    faEllipsisH,
    faSatelliteDish,
    faCheck,
    faCheckCircle,
    faRobot,
    faCheckDouble,
    faUserTag,
    faInfoCircle,
    faUserEdit,
    faUserPlus,
    faEdit,
    faForward,
    faSignal,
    faPhoneAlt,
    faPhoneVolume,
    faUser,
    faSpinner,
    faCrown,
    faMoneyBillAlt,
    faInfo,
    faHandshake,
    faUndo,
    faUserLock,
    faCopy,
    faFile,
    faPlus,
    faFileInvoiceDollar,
    faLayerGroup,
    faFileDownload,
    faMinusSquare,
    faSave,
    faChartPie,
    faTable,
    faSquare,
    faCog,
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faReceipt,
    faExternalLinkAlt,
    faMoneyCheckAlt,
    faCreditCard,
    faDonate,
    faRandom,
    // regular icons go here
    faFrownOpen,
    faPlayCircle,
    farFaQuestionCircle,
    faCircle,
    faSmile,
    faFrown,
    faChartBar,
    farFaTimesCircle,
    faBell,
    // brand icons go here
    faCcMastercard,
    faCcVisa,
    faCcAmex
);
