export const itemDrawerTabItemCardClassName = 'card-custom-details-thick';
export const fileTypesSignature = {
    png: ['89504E47'],
    gif: ['47494638'],
    jpg: ['FFD8FFDB', 'FFD8FFE0', 'FFD8FFE1', 'FFD8FFEE'],
    // txt: [
    //     '46545854',
    //     '4F70656E',
    //     '436F7079',
    //     '7374646F',
    //     '446F776E',
    //     '53656520',
    //     'A6C6962',
    //     '31313A31',
    //     '30393A35',
    //     '31303A34',
    //     '464F524D',
    // ],
    doc: ['D0CF11E0A1B11AE1'],
    docx: ['504B0304'],
    pdf: ['25504446'],
    xls: ['D0CF11E0A1B11AE1'],
    xlsx: ['504B0304', '504B34'],
};

export const companyGlobeIconList = ['asia', 'europe', 'americas', 'africa'];

export const dateTypeFilterStateNames = [
    'CreatedDate',
    'DueDate',
    'ActionDate',
    'ClosedDate',
    'AvailableDate',
    'PaidDate',
];

export const companyLocaleDependentFilterNames = {
    AMOUNT: 'Amount',
};

export const companyLocaleDependentFilters = [
    companyLocaleDependentFilterNames.AMOUNT,
];

export const conversationTypes = {
    Comment: 'Comment',
    DeliveryTracking: 'DeliveryTracking',
    EntityStateChange: 'EntityStateChange',
};

export const conversationTypeClassifications = {
    Comment: 'Comment',
    AutomatedSMS: 'AutomatedSMS',
    AutomatedEmail: 'AutomatedEmail',
    EmailOpened: 'EmailOpened',
    NewTicket: 'NewTicket',
    ResolvedTicket: 'ResolvedTicket',
    FailedEmailDelivery: 'FailedEmailDelivery',
};

export const dateSelectOptions = {
    THIS_MONTH: 'This month',
    NEXT_MONTH: 'Next month',
    LAST_MONTH: 'Last month',
    LAST_7_DAYS: 'Last 7 days',
    LAST_30_DAYS: 'Last 30 days',
    NEXT_7_DAYS: 'Next 7 days',
    NEXT_30_DAYS: 'Next 30 days',
    CUSTOM_DATE_RANGE: 'Custom date range',
    CUSTOM_DAYS_RANGE: 'Custom days range',
    NOW: 'Now',
    WITHIN_THIS_WEEK: 'Within this week',
    WITHIN_NEXT_7_DAYS: 'Within next 7 days',
    LAST_WEEK_UP_TO_NOW: 'Last week up to now',
    TODAY: 'Today',
    CREATE_DATE_ATB_CURRENT: 'Create date ATB: Current',
    CREATE_DATE_ATB_30_DAYS: 'Create date ATB: 30 days',
    CREATE_DATE_ATB_60_DAYS: 'Create date ATB: 60 days',
    CREATE_DATE_ATB_90_PLUS_DAYS: 'Create date ATB: 90+ days',
    DUE_DATE_ATB_NOT_DUE: 'Due date ATB: Not due',
    DUE_DATE_ATB_CURRENT: 'Due date ATB: Current',
    DUE_DATE_ATB_30_DAYS: 'Due date ATB: 30 days',
    DUE_DATE_ATB_60_DAYS: 'Due date ATB: 60 days',
    DUE_DATE_ATB_90_PLUS_DAYS: 'Due date ATB: 90+ days',
    AVAILABLE_DATE_ATB_NOT_AVAILABLE: 'Available date ATB: Not available',
    AVAILABLE_DATE_ATB_CURRENT: 'Available date ATB: Current',
    AVAILABLE_DATE_ATB_30_DAYS: 'Available date ATB: 30 days',
    AVAILABLE_DATE_ATB_60_DAYS: 'Available date ATB: 60 days',
    AVAILABLE_DATE_ATB_90_PLUS_DAYS: 'Available date ATB: 90+ days',
};

export const selectSearchTypeFilterStateNames = ['CompanyId'];

export const selectSearchFilterMapping: any = {
    CompanyId: 'CompanyId',
};
