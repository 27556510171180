/**
 * HOC Function for populating automation icons.
 */

import React from 'react';
import {
    taskHistoryAutomation,
    taskHistoryBatchTypes,
} from '../../constants/tasksSortAndFilters';
import FontAwesome from './FontAwesome';
import { getIfIsLg } from '../../utils/commonFunctions';

export const withAutomationIconsHandler = (WrappedComponent: any) => {
    const AutomationIconsHandler: React.FC<any> = (props: any) => {
        /**
         * HOC function for generating the automation icon
         * @param type - type of task history
         * @param automation - type of automation (usually for communication types)
         * @param iconColor - color of the icon itself
         * @param iconCircleColor - color of the circle border surrounding the icon
         * @param fontSizeProp - font size property
         */
        const generateAutomationIcon = (
            type: string,
            automation: string | null,
            iconColor: string,
            iconCircleColor?: string | null,
            fontSizeProp?: number | string | null
        ) => {
            const isLg = getIfIsLg();
            const fontSize = fontSizeProp || (isLg ? 24 : undefined);
            /**
             * automated notification
             */
            if (
                type === taskHistoryBatchTypes.Communication &&
                automation === taskHistoryAutomation.Automatic
            ) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                            />
                            <FontAwesome
                                icon={['fas', 'satellite-dish']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-7"
                            />
                            <FontAwesome
                                icon={['fas', 'robot']}
                                className={iconColor}
                                transform="shrink-9 down-12 right-16"
                                // style={{ fontSize: 12 }}
                            />
                        </div>
                    </>
                );
            } else if (
                type === taskHistoryBatchTypes.Communication &&
                automation === taskHistoryAutomation.Manual
            ) {
                /**
                 * manual notifications
                 */
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                            />
                            <FontAwesome
                                icon={['fas', 'phone-volume']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-7 right-2"
                            />
                            <FontAwesome
                                icon={['fas', 'user']}
                                className={iconColor}
                                transform="shrink-9 down-12 right-16"
                                // style={{ fontSize: 12 }}
                            />
                        </div>
                    </>
                );
            } else if (
                type === taskHistoryBatchTypes.Query ||
                type === taskHistoryBatchTypes.Ticket
            ) {
                /**
                 * resolved tickets
                 */
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="left-3"
                            />
                            <FontAwesome
                                icon={['fas', 'question-circle']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-6 left-3"
                            />
                        </div>
                    </>
                );
            }
            /**
             * rejected approval to send notifications
             */
            // return (
            //     <>
            //         <div className="fa-layers fa-fw fa-2x">
            //             <FontAwesome
            //                 icon={['far', 'circle']}
            //                 className={iconCircleColor || iconColor}
            //                 size="2x"
            //             />
            //             <FontAwesome
            //                 icon={['fas', 'check']}
            //                 className={iconColor}
            //                 size="2x"
            //                 transform="shrink-7"
            //             />
            //         </div>
            //     </>
            // );
            /**
             * changed tasks
             */
            else if (type === taskHistoryBatchTypes.Changed) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="left-3"
                            />
                            <FontAwesome
                                icon={['fas', 'edit']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-8 left-3"
                            />
                        </div>
                    </>
                );
            } else if (type === taskHistoryBatchTypes.Collection) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                            />
                            <FontAwesome
                                icon={['fas', 'signal']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-8"
                            />
                            <FontAwesome
                                icon={['fas', 'robot']}
                                className={iconColor}
                                transform="shrink-9 down-12 right-16"
                                // style={{ fontSize: 12 }}
                            />
                        </div>
                    </>
                );
            }
            /**
             * requested approval to send notifications
             */
            // return (
            //     <>
            //         <div className="fa-layers fa-fw fa-2x">
            //             <FontAwesome
            //                 icon={['far', 'circle']}
            //                 className={iconCircleColor || iconColor}
            //                 size="2x"
            //             />
            //             <FontAwesome
            //                 icon={['fas', 'phone-volume']}
            //                 className={iconColor}
            //                 size="2x"
            //                 transform="shrink-7 right-2"
            //             />
            //         </div>
            //         <div className={smallBtmClass}>
            //             <FontAwesome
            //                 icon={['fas', 'robot']}
            //                 className={iconColor}
            //             />
            //         </div>
            //     </>
            // );
            /**
             * reassigned tasks
             */
            // return (
            //     <>
            //         <div className="fa-layers fa-fw fa-2x">
            //             <FontAwesome
            //                 icon={['far', 'circle']}
            //                 className={iconCircleColor || iconColor}
            //                 size="2x"
            //             />
            //             <FontAwesome
            //                 icon={['fas', 'user-tag']}
            //                 className={iconColor}
            //                 size="2x"
            //                 transform="shrink-9 left-1"
            //             />
            //         </div>
            //     </>
            // );
            /**
             * added notes to task
             */
            // return (
            //     <>
            //         <div className="fa-layers fa-fw fa-2x">
            //             <FontAwesome
            //                 icon={['far', 'circle']}
            //                 className={iconCircleColor || iconColor}
            //                 size="2x"
            //             />
            //             <FontAwesome
            //                 icon={['fas', 'comment']}
            //                 className={iconColor}
            //                 size="2x"
            //                 transform="shrink-8"
            //             />
            //         </div>
            //     </>
            // );
        };

        return (
            <WrappedComponent
                {...props}
                generateAutomationIcon={generateAutomationIcon}
            />
        );
    };

    return AutomationIconsHandler;
};
