/**
 * Index component considered to be the root file of the app.
 */

import { Spin } from 'antd';
import { ConnectedRouter } from 'connected-react-router';
import 'core-js/stable';
import { createBrowserHistory } from 'history';
import React, { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import App from './components/App/App';
import PopupCheckerComponent from './components/common/PopupCheckerComponent';
import configureStore from './configureStore';
import './fontawesomeIcons';
import './less/main.less';
import ProtectedRoute from './ProtectedRoute';
import * as serviceWorker from './serviceWorker';

const AppWithAuth = lazy(() => import('./components/App/AppWithAuth'));

declare global {
    interface Window {
        less: any;
    }
}

interface IProps {}

const Root: React.FC<IProps> = () => {
    const history = createBrowserHistory();
    const initialState = window.initialReduxState;
    const store = configureStore(history, initialState);
    // if (localStorage.getItem(TASK_ID_URL_QUERY))
    //     localStorage.removeItem(TASK_ID_URL_QUERY);

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Suspense
                    fallback={
                        <Spin
                            className="w-100 center-flex-all ta-center"
                            style={{ height: window.innerHeight }}
                        />
                    }
                >
                    <Switch>
                        <Route path="/" exact component={AppWithAuth} />
                        <Route
                            path="/pop-up-checker"
                            exact
                            component={PopupCheckerComponent}
                        />
                        <ProtectedRoute path="/app" component={App} />
                        <Redirect to="/" />
                    </Switch>
                </Suspense>
            </ConnectedRouter>
        </Provider>
    );
};

render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
