import API, { graphqlOperation } from '@aws-amplify/api';
import { find, get, isEmpty } from 'lodash';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    initialPrimaryColor,
    initialSecondaryColor,
} from '../../config/config';
import { CompanyIdAttribute } from '../../constants/authUserAttributes';
import queries from '../../graphql/queries.graphql';
import { updateLessVariables } from '../../utils/commonFunctions';
import { setLocaleAction } from '../common/actions';
import { getCurrentUser } from '../users/sagas';
import { DEFAULT_LOCALE } from './../../config/config';
import { DynamicObject } from './../../utils/commonInterfaces';
import {
    getUserCompaniesSuccessAction,
    saveUserCompanyResponseAction,
    selectUserCompanyRequestAction,
    selectUserCompanySuccessAction,
} from './actions';
import { CompaniesActionTypes, Company } from './types';

/**
 * Function used in useSelector for getting the selected usaer company from redux state.
 */
export const getUserSelectedCompany = (state: ApplicationState) =>
    state.companies.selectedUserCompany;

/**
 * Function used in useSelector for getting the boolean indicator for customer workflow flag in currently selected company.
 */
export const getUserCompanyCustomerWorkflowFlag = (state: ApplicationState) =>
    get(state.companies.selectedUserCompany, 'Company.UsingCustomerWorkflow');

/**
 * Function used in useSelector for getting the role name of the current logged in user.
 */
export const getUserCompanyRole = (state: ApplicationState) =>
    get(state.companies.selectedUserCompany, 'Role.Name');

/**
 * Function used in useSelector for getting the company's language pack.
 */
export const getLanguagePack = (state: ApplicationState) =>
    get(state.companies.selectedUserCompany, 'Company.LanguagePackage');

/**
 * Function responsible for getting the companies the user belongs to from API.
 */
function* handleGetUserCompaniesRequest() {
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_COMPANIES_FOR_EXTERNAL_USER)
        );
        const companiesData = get(
            res.data,
            'GetCompaniesForExternalUser.Companies'
        );

        yield put(getUserCompaniesSuccessAction(companiesData));
        if (!isEmpty(companiesData)) {
            const currentUser: DynamicObject = yield select(getCurrentUser);
            const selectedCompanyId = get(currentUser, CompanyIdAttribute);

            if (!selectedCompanyId) {
                yield put(selectUserCompanyRequestAction(companiesData[0]));
            } else {
                const currentCompanyData = find(
                    companiesData,
                    (userCompanyData: Company) =>
                        selectedCompanyId === userCompanyData.CompanyId
                );

                const selectedCompanyData =
                    currentCompanyData || companiesData[0];
                yield put(selectUserCompanyRequestAction(selectedCompanyData));
            }
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        yield put(getUserCompaniesSuccessAction([]));
    }
}

/**
 * Function responsible for triggering the user company selected
 * and also handles company switching to sync the company selected here in UI
 * and the one stored in cognito.
 */
function* handleSelectUserCompanyRequest({
    payload: companyUserRoleObject,
}: any) {
    try {
        const companyRegion = get(companyUserRoleObject, 'Region');
        if (companyRegion) {
            const companyLocale = get(
                companyUserRoleObject,
                'LanguagePackage.Language',
                DEFAULT_LOCALE
            );
            if (companyLocale) {
                const languagePack: DynamicObject = yield select(
                    getLanguagePack
                );
                const currentLocale = get(languagePack, 'Language');
                if (currentLocale !== companyLocale) {
                    yield put(setLocaleAction(companyLocale));
                }
            }
        }

        yield put(selectUserCompanySuccessAction(companyUserRoleObject));
        // calls for queries if needed
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called after all the functions called when switching the company has finished.
 * Responsible for syncing the Theme for the company.
 */
// eslint-disable-next-line
function* handleSelectUserCompanySuccess({
    payload: companyUserRoleObject,
}: any) {
    try {
        const companyObject = get(companyUserRoleObject, 'Company');
        const primaryColorTheme =
            get(companyObject, 'PrimaryColorTheme') || initialPrimaryColor;
        const secondaryColorTheme =
            get(companyObject, 'SecondaryColorTheme') || initialSecondaryColor;

        updateLessVariables({
            '@custom-primary-color': primaryColorTheme,
            '@custom-secondary-color': secondaryColorTheme,
        });

        return false;
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for saving the company details - talking to the API.
 */
function* handleSaveUserCompanyRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        yield call([API, 'post'], API_NAME, '/company/save', {
            body: payload,
        });

        yield put(saveUserCompanyResponseAction());

        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
        // calls for queries if needed
    } catch (err) {
        yield put(saveUserCompanyResponseAction());
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for saving the company logo.
 */
function* handleUploadCompanyLogoRequest({ payload: sagaPayload }: any) {
    const { fileToUpload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/company/upload/logo',
            {
                body: fileToUpload.preview,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetUserCompaniesRequest() {
    yield takeLatest(
        CompaniesActionTypes.GET_USER_COMPANIES_REQUEST,
        handleGetUserCompaniesRequest
    );
}

function* watchSelectUserCompanyRequest() {
    yield takeLatest(
        CompaniesActionTypes.SELECT_USER_COMPANY_REQUEST,
        handleSelectUserCompanyRequest
    );
}

function* watchSelectUserCompanySuccess() {
    yield takeLatest(
        CompaniesActionTypes.SELECT_USER_COMPANY_SUCCESS,
        handleSelectUserCompanySuccess
    );
}

function* watchSaveUserCompanyRequest() {
    yield takeLatest(
        CompaniesActionTypes.SAVE_USER_COMPANY_REQUEST,
        handleSaveUserCompanyRequest
    );
}

function* watchUploadCompanyLogoRequest() {
    yield takeLatest(
        CompaniesActionTypes.UPLOAD_COMPANY_LOGO_REQUEST,
        handleUploadCompanyLogoRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* companiesSaga() {
    yield all([
        fork(watchGetUserCompaniesRequest),
        fork(watchSelectUserCompanyRequest),
        fork(watchSelectUserCompanySuccess),
        fork(watchSaveUserCompanyRequest),
        fork(watchUploadCompanyLogoRequest),
    ]);
}

export default companiesSaga;
