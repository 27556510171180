import { get } from 'lodash';
import { all } from 'redux-saga/effects';
import { ApplicationState } from '..';

/**
 * Function for getting the currentUser
 */
export const getUserRole = (state: ApplicationState) =>
    get(state.auth.currentUser, 'Role.Name', '');

export const getAllRoles = (state: ApplicationState) => state.roles.allRoles;
export const getRolePermissions = (state: ApplicationState) =>
    state.roles.rolePermissions;

// We can also use `fork()` here to split our saga into multiple watchers.
function* rolesSaga() {
    yield all([]);
}

export default rolesSaga;
