import { all } from 'redux-saga/effects';
import { ApplicationState } from '..';

/**
 * Function for getting the currentUser
 */
export const getCurrentUser = (state: ApplicationState) =>
    state.auth.currentUser;

/**
 * Function used in useSelector for getting the region setting config.
 */
export const getRegionSettingConfig = (state: ApplicationState) =>
    state.auth.regionSettingsConfig;

/**
 * Function used in useSelector for getting the region key config.
 */
export const getRegionKeyConfig = (state: ApplicationState) =>
    state.auth.regionKeyConfig;

// We can also use `fork()` here to split our saga into multiple watchers.
function* authSaga() {
    yield all([]);
}

export default authSaga;
