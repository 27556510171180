import { checkIfDateHasPassed } from '../utils/commonFunctions';

export const preConfiguredFilters = {
    ALL_ACTIVE_TASKS: 'All active tasks',
    ALL_CUSTOMER_TICKET_TASKS: 'All customer ticket tasks',
    ALL_READY_AUTOMATED_TASKS: 'All ready - automated tasks',
    ALL_READY_MANUAL_TASKS: 'All ready - manual tasks',
    ALL_APPROVAL_TASKS: 'All approval tasks',
    ALL_READY_ESCALATE_TO_COLLECTIONS: 'All ready - escalate to collections',
};

export const tasksSortByOptions = [
    // { label: 'Priority', value: 'Priority' },
    { label: 'Type', value: 'Type' },
    { label: 'Ready state', value: 'ActionDate' },
    // { label: 'Company name', value: 'CompanyName' },
    // { label: 'Customer code', value: 'CustomerCode' },
    { label: 'Workflow step', value: 'StateName' },
    // { label: 'Invoice number', value: 'Number' },
    // { label: 'Amount', value: 'AmountOwing' },
];

export const tasksStatusFilterOptions = [
    { label: 'All', value: -1 },
    { label: 'In progress', value: 0 },
    { label: 'Available', value: 1 },
    // { label: 'On hold', value: 2 },
    // { label: 'Closed', value: 3 },
];

export const tasksAmountType = [
    { label: 'Owing', value: 'Owing' },
    // { label: 'Total', value: 'Total' },
];

export const tasksTypeOptions = [
    {
        label: 'Automated communication',
        value: 1,
    },
    {
        label: 'Customer ticket',
        value: 2,
    },
    {
        label: 'Escalate to collections',
        value: 4,
    },

    // {
    //     label: 'Approval',
    //     value: 1,
    // },
    // {
    //     label: 'Manual communication',
    //     value: 8,
    // },
];

export const tasksPriorityTypeOptions = [
    {
        label: 'Low',
        value: 1,
    },
    {
        label: 'Medium',
        value: 2,
    },
    {
        label: 'High',
        value: 4,
    },
];

export const dateSelectOptions = {
    THIS_MONTH: 'This month',
    NEXT_MONTH: 'Next month',
    LAST_MONTH: 'Last month',
    LAST_7_DAYS: 'Last 7 days',
    LAST_30_DAYS: 'Last 30 days',
    NEXT_7_DAYS: 'Next 7 days',
    NEXT_30_DAYS: 'Next 30 days',
    CUSTOM_DATE_RANGE: 'Custom date range',
    CUSTOM_DAYS_RANGE: 'Custom days range',
    NOW: 'Now',
    WITHIN_THIS_WEEK: 'Within this week',
    WITHIN_NEXT_7_DAYS: 'Within next 7 days',
    LAST_WEEK_UP_TO_NOW: 'Last week up to now',
    TODAY: 'Today',
    CREATE_DATE_ATB_CURRENT: 'Create date ATB: Current',
    CREATE_DATE_ATB_30_DAYS: 'Create date ATB: 30 days',
    CREATE_DATE_ATB_60_DAYS: 'Create date ATB: 60 days',
    CREATE_DATE_ATB_90_PLUS_DAYS: 'Create date ATB: 90+ days',
    DUE_DATE_ATB_NOT_DUE: 'Due date ATB: Not due',
    DUE_DATE_ATB_CURRENT: 'Due date ATB: Current',
    DUE_DATE_ATB_30_DAYS: 'Due date ATB: 30 days',
    DUE_DATE_ATB_60_DAYS: 'Due date ATB: 60 days',
    DUE_DATE_ATB_90_PLUS_DAYS: 'Due date ATB: 90+ days',
    AVAILABLE_DATE_ATB_NOT_AVAILABLE: 'Available date ATB: Not available',
    AVAILABLE_DATE_ATB_CURRENT: 'Available date ATB: Current',
    AVAILABLE_DATE_ATB_30_DAYS: 'Available date ATB: 30 days',
    AVAILABLE_DATE_ATB_60_DAYS: 'Available date ATB: 60 days',
    AVAILABLE_DATE_ATB_90_PLUS_DAYS: 'Available date ATB: 90+ days',
};

const {
    NOW,
    WITHIN_THIS_WEEK,
    WITHIN_NEXT_7_DAYS,
    LAST_WEEK_UP_TO_NOW,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
    TODAY,
    THIS_MONTH,
    LAST_MONTH,
} = dateSelectOptions;
export const tasksReadyStateOptions = [
    NOW,
    WITHIN_THIS_WEEK,
    WITHIN_NEXT_7_DAYS,
    LAST_WEEK_UP_TO_NOW,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

// TASK HISTORY
export const taskHistoryTypeOptions = [
    // { label: 'Approval', value: 32 },
    {
        label: 'Automated communication',
        value: 1,
    },
    // { label: 'Manual communication', value: 4 },
    { label: 'Customer ticket', value: 2 },
    { label: 'Task changes', value: 8 },
    { label: 'Escalate to collections', value: 4 },
];

export const taskHistoryActionedDateOptions = [
    TODAY,
    WITHIN_THIS_WEEK,
    THIS_MONTH,
    LAST_MONTH,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const taskHistorySortByOptions = [
    { label: 'Type', value: 'Type' },
    { label: 'Date actioned', value: 'BatchActionDate' },
    // { label: 'User', value: 'User' },
    { label: 'Status', value: 'Status' },
];

export const preConfiguredTaskHistoryFilters = {
    ALL_ACTIONED_TASKS: 'All actioned tasks',
    ALL_TASKS_ACTIONED_TODAY: 'All tasks actioned today',
    ALL_TASKS_ACTIONED_THIS_WEEK: 'All tasks actioned this week',
};

// TASK HISTORY ITEM DETAILS
// Notification
export const taskHistoryItemDetailsNotificationSortOptions = [
    // { label: 'Type', value: 'Type' },
    { label: 'Customer code', value: 'CustomerCode' },
    { label: 'Customer name', value: 'CompanyName' },
    { label: 'Workflow step', value: 'StateName' },
    { label: 'Status', value: 'Status' },
];

export const taskHistoryBatchTypes = {
    Communication: 'Communication',
    Query: 'Query',
    Changed: 'Changed',
    Ticket: 'Ticket',
    Collection: 'Collection',
    PaymentSchedule: 'PaymentSchedule',
    PaymentPlan: 'PaymentPlan',
};

export const taskHistoryBatchStates = {
    InProgress: {
        label: 'In progress',
        value: 'InProgress',
    },
    Completed: { label: 'Completed', value: 'Completed' },
    Failed: { label: 'Failed', value: 'Failed' },
    Skipped: { label: 'Skipped', value: 'Skipped' },
};

export const taskHistoryAutomation = {
    Automatic: 'Automatic',
    Manual: 'Manual',
};

export const ContactFilterOptions: any = {
    Email: 1,
    Mobile: 2,
    PostalAddress: 4,
};

export const taskHistoryDrawerTypes = {
    AutomatedCommunication: 'AutomatedCommunication',
    ChangedTasks: 'ChangedTasks',
    ResolvedTicket: 'ResolvedTicket',
    Collections: 'Collections',
};

export const communicationState = {
    Pending: 0,
    InProgress: 1,
    Processed: 2,
    Failed: 3,
};

export const taskStatusFilterOptions = [
    {
        label: 'Sent',
        value: communicationState.Processed,
    },
    {
        label: 'Pending',
        value: communicationState.Pending,
    },
    {
        label: 'Failed',
        value: communicationState.Failed,
    },
];

export const taskItemColorsAndLabel = (
    type: string,
    stateName: string,
    isTaskReady: boolean,
    actionDate: string
) => {
    let itemColor = 'green';
    let readyLabel = 'Ready since';
    let iconCircleColor = 'green';
    let iconContentColor = 'green';

    if (type === taskHistoryBatchTypes.Ticket) {
        iconCircleColor = 'orange';
        iconContentColor = 'orange';
    } else if (type === taskHistoryBatchTypes.Collection) {
        iconCircleColor = 'red';
        iconContentColor = 'red';
    }

    if (!isTaskReady) {
        itemColor = 'black';
        readyLabel = checkIfDateHasPassed(actionDate)
            ? 'Ready since'
            : 'Pending until';
        iconContentColor = 'greyb3';
    }

    return {
        itemColor,
        readyLabel,
        iconCircleColor,
        iconContentColor,
    };
};

export const extraInformationLabels: any = {
    PaymentDate: 'Date of payment',
    AmountPaid: 'Amount',
    Information: 'Other information',
};

// Task details delivery
export const taskHistoryDetailsDeliverySortByOptions = [
    { label: 'Customer code', value: 'CustomerCode' },
    { label: 'Customer name', value: 'CustomerName' },
    { label: 'Workflow Step', value: 'WorkflowStep' },
];