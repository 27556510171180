/**
 * Function responsible for showing modal component with loading spinner.
 * Mostly used when saving data or when waiting (connection to API).
 */

import { Modal } from 'antd';
import React from 'react';
import { populatePopoverContainer } from '../../utils/commonFunctions';
import LoadingSpinner from './LoadingSpinner';

interface IProps {
    readonly modalTitle: string;
    readonly modalVisible: boolean;
    readonly displayMessage: string;
    readonly containerRef?: any;
}
const ModalWithSpinner: React.FC<IProps> = (props: IProps) => {
    return (
        <Modal
            title={props.modalTitle}
            visible={props.modalVisible}
            footer={null}
            closable={false}
            getContainer={populatePopoverContainer(props.containerRef)}
        >
            <div style={{ height: 140 }}>
                <LoadingSpinner displayMessage={props.displayMessage} />
            </div>
        </Modal>
    );
};

export default ModalWithSpinner;
