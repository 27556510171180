import { DynamicObject } from '../../utils/commonInterfaces';

export enum AuthActionTypes {
    LOGIN_USER = '@@auth/LOGIN_USER',
    LOGOUT_USER = '@@auth/LOGOUT_USER',
    RESET_REDUX_STATE = '@@auth/RESET_REDUX_STATE',
    CHANGE_ENDPOINTS = '@@auth/CHANGE_ENDPOINTS',
    GET_REGION_KEY_CONFIG = '@@auth/GET_REGION_KEY_CONFIG',
    UPDATE_REGION_KEY_CONFIG = '@@auth/UPDATE_REGION_KEY_CONFIG',
    GET_REGION_SETTINGS_CONFIG = '@@auth/GET_REGION_SETTINGS_CONFIG',
    UPDATE_REGION_SETTINGS_CONFIG = '@@auth/UPDATE_REGION_SETTINGS_CONFIG',
}

export interface AuthState {
    readonly isAuth: boolean;
    readonly currentUser: any;
    readonly regionSettingsConfig: DynamicObject[];
    readonly regionKeyConfig: DynamicObject[];
}
