import { action } from 'typesafe-actions';
import { ResponseModalObject } from '../../utils/commonInterfaces';
import { CompaniesActionTypes, Company, SystemConfiguration } from './types';

/**
 * Actions for getting the user companies,
 * this includes company user roles.
 */
export const getUserCompaniesRequestAction = () =>
    action(CompaniesActionTypes.GET_USER_COMPANIES_REQUEST);
export const getUserCompaniesSuccessAction = (data: Company[]) =>
    action(CompaniesActionTypes.GET_USER_COMPANIES_SUCCESS, data);

/**
 * Actions triggered to determine which company the user has selected - for UI manipilation
 * since all API calls depend on the Company saved at cognito and not here.
 * @param companyUserRoleObject
 */
export const selectUserCompanyRequestAction = (
    companyUserRoleObject: Company
) =>
    action(
        CompaniesActionTypes.SELECT_USER_COMPANY_REQUEST,
        companyUserRoleObject
    );
export const selectUserCompanySuccessAction = (selectedCompany: Company) =>
    action(CompaniesActionTypes.SELECT_USER_COMPANY_SUCCESS, selectedCompany);

/**
 * Actions called for saving company data.
 * @param payload
 * @param callback - function called after saving - useful for refetching the company details
 * and showing alert modals
 */
export const saveUserCompanyRequestAction = (
    payload: any,
    callback?: (response: ResponseModalObject) => void
) =>
    action(CompaniesActionTypes.SAVE_USER_COMPANY_REQUEST, {
        payload,
        callback,
    });
export const saveUserCompanyResponseAction = () =>
    action(CompaniesActionTypes.SAVE_USER_COMPANY_RESPONSE);

/**
 * Action called for uploading company logo.
 * @param fileToUpload
 * @param callback - function called after saving and used for showing modal response
 */
export const uploadCompanyLogoRequestAction = (
    fileToUpload: any,
    callback: (response: ResponseModalObject) => void
) =>
    action(CompaniesActionTypes.UPLOAD_COMPANY_LOGO_REQUEST, {
        fileToUpload,
        callback,
    });

/**
 * Action responsible for getting the system configuration.
 * To control the banner at the page header showing what mode the system is in.
 * Dev, Production, Test, and such.
 * @param data
 */
export const getSystemConfigurationSuccessAction = (
    data: SystemConfiguration
) => action(CompaniesActionTypes.GET_SYSTEM_CONFIGURATION_SUCCESS, data);

/**
 * Action that resets all company table data filters and sort values to initial state.
 */
export const resetAllCompanyDataTableFiltersAction = () =>
    action(CompaniesActionTypes.RESET_ALL_COMPANY_DATA_TABLE_FILTERS);
