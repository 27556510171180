import AutoComplete from 'antd/lib/auto-complete';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal/Modal';
import Select, { SelectValue } from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import { API } from 'aws-amplify';
import {
    clone,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    lowerCase,
    map,
} from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { selectSearchFilterMapping } from '../../constants/common';
import queries from '../../graphql/queries.graphql';
import {
    checkIsValidJsonString,
    populatePopoverContainer,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
const { Option } = Select;

const FILTER_SEARCH_DEBOUNCE_TIME = 500; //ms
let unmounted: boolean = false;
interface IProps {
    updateField: (value: any) => void;
    stateValue: string;
    appliedValue: string;
    queryName: string;
    filterField: string | undefined;
    responseName: string;
    labelField: string[];
    updateFiltersFunction: (defaultValue?: any) => void;
}
let timeoutHandle: any = null;

const InputSelectUserSearchWithButton: React.FC<IProps> = ({
    updateField,
    stateValue,
    appliedValue,
    queryName,
    filterField,
    responseName,
    labelField,
    updateFiltersFunction,
}: IProps) => {
    const containerRef = useRef(null);
    const [inputState, setInputState] = useState<{
        fetching: boolean;
        value: string | undefined;
    }>({
        fetching: false,
        value: stateValue || undefined,
    });

    const [dataState, setDataState] = useState<{
        data: [];
        filteredData: [];
    }>({
        data: [],
        filteredData: [],
    });

    /**
     * Common function for updating the input state.
     * @param inputStateObject - must conform to inputState object
     */
    const updateInputState = (inputStateObject: {}) => {
        setInputState({
            ...inputState,
            ...inputStateObject,
        });
    };

    /**
     * Common function for updating the data state.
     * @param dataStateObject - must conform to dataState object
     */
    const updateDataState = (dataStateObject: {}) => {
        setDataState({
            ...dataState,
            ...dataStateObject,
        });
    };

    const checkStateValueUpdate = () => {
        if (!stateValue) {
            updateInputState({
                fetching: false,
                value: stateValue || undefined,
            });
            updateDataState({
                data: [],
            });
        }
    };

    useEffect(checkStateValueUpdate, [stateValue]);

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmounted = false;
        getDataOptions();
        //will unmount
        return () => {
            unmounted = true;
        };
    };

    useEffect(setInitialLoad, []);

    const { fetching, value } = inputState;

    const fetchOptions = (value: string) => {
        if (!labelField) return;
        if (timeoutHandle) clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(() => {
            const { data } = dataState;
            updateInputState({ fetching: true });
            const filteredData = filter(data, (d: DynamicObject) => {
                const label = getLabelFromLabelField(d);
                const lcName = lowerCase(label);
                const lcFilter = lowerCase(value);
                return includes(lcName, lcFilter);
            });
            updateInputState({ value, fetching: false });
            updateDataState({ filteredData });
        }, FILTER_SEARCH_DEBOUNCE_TIME);
    };

    const getDataOptions = () => {
        updateInputState({ fetching: true });
        updateDataState({ data: [], filteredData: [] });

        const apiGraphQL = API.graphql({
            query: queries[queryName],
        }) as Promise<any>;

        apiGraphQL
            .then((res: any) => {
                if (unmounted) return;
                const data: DynamicObject[] = [];
                forEach(
                    get(res, `data.${responseName}`),
                    (opt: DynamicObject) => {
                        if (!includes(data, opt)) {
                            data.push(opt);
                        }
                    }
                );

                updateInputState({
                    value,
                    fetching: false,
                });
                updateDataState({
                    data,
                    filteredData: data,
                });
            })
            .catch(() => {
                if (unmounted) return;
                updateInputState({
                    value,
                    fetching: false,
                });
                updateDataState({
                    data: [],
                    filteredData: [],
                });
            });
    };

    const setValueFromStateVal = () => {
        if (stateValue !== value) {
            updateInputState({ value: stateValue });
        }
    };

    useEffect(setValueFromStateVal, [stateValue]);

    const handleChange = (value: SelectValue) => {
        updateInputState({
            value: value as string,
        });
    };

    const handleSelect = (value: SelectValue) => {
        updateField(value);
    };

    const onOkClick = () => {
        if (timeoutHandle) clearTimeout(timeoutHandle);

        if (
            value &&
            (checkIsValidJsonString(value) ||
                checkIsValidJsonString(appliedValue))
        ) {
            updateInputState({
                fetching: false,
                value: value || undefined,
            });

            if (stateValue === value) {
                return updateFiltersFunction(appliedValue);
            } else {
                let valueUsed = clone(value);
                return updateFiltersFunction(valueUsed);
            }
        }

        if (!isEmpty(value)) {
            const contentError = 'Please select a company from the list!';

            return Modal.error({
                title: 'Error',
                content: contentError,
                getContainer: populatePopoverContainer(containerRef),
                // onOk: () => {
                //     if (value) getDataOptions(value);
                // },
            });
        } else {
            return updateFiltersFunction(null);
        }
    };

    const getLabelFromLabelField = (d: DynamicObject) => {
        let label = '';
        forEach(labelField, (lf: string) => {
            if (label !== '') label += ' ';
            label += get(d, lf) || '';
        });
        return label;
    };

    const getSelectOptions: () => any = () => {
        if (!filterField) return null;
        const { filteredData } = dataState;
        return map(filteredData, (d: DynamicObject) => {
            const label = getLabelFromLabelField(d);
            const keyUsed =
                get(d, filterField) ||
                get(d, get(selectSearchFilterMapping, filterField, '--'));
            return (
                <Option key={keyUsed} value={JSON.stringify(d)}>
                    {label}
                </Option>
            );
        });
    };

    return (
        <div className="pop-action-content" ref={containerRef}>
            <div>
                <AutoComplete
                    className="autocomplete-input"
                    value={value}
                    dataSource={getSelectOptions()}
                    notFoundContent={null}
                    filterOption={false}
                    onSearch={fetchOptions}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    dropdownClassName="autocomplete-input-dropdown"
                    getPopupContainer={populatePopoverContainer(containerRef)}
                >
                    <Input
                        prefix={
                            fetching ? (
                                <Spin
                                    indicator={
                                        <Icon
                                            type="loading"
                                            style={{ fontSize: 12 }}
                                            spin
                                        />
                                    }
                                />
                            ) : (
                                <Icon type="search" />
                            )
                        }
                        placeholder="Search"
                        allowClear
                        onPressEnter={onOkClick}
                        value={value}
                        disabled={isEmpty(dataState.data)}
                    />
                </AutoComplete>
            </div>
            <div className="ok-button-container">
                <Button type="primary" onClick={onOkClick}>
                    Ok
                </Button>
            </div>
        </div>
    );
};

export default InputSelectUserSearchWithButton;
