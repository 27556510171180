import update from 'immutability-helper';
import { concat, get, isEqual, uniqWith } from 'lodash';
import { Reducer } from 'redux';
import {
    DETAILS_TAB,
    TASKS_PAGE
} from '../../config/tableAndPageConstants';
import {
    tasksSortByOptions,
    tasksStatusFilterOptions,
} from '../../constants/tasksSortAndFilters';
import { TasksActionTypes, TasksState } from './types';
import { paymentScheduleSortByOptions } from '../../constants/paymentPlansSortAndFilters';
import {
    commonInvoiceListInvoicesSortOptions,
    invoicesStateFilterOptions,
} from '../../constants/invoicesSortAndFilters';


// Type-safe initialState!
export const initialState: TasksState = {
    activeTasks: {
        loading: false,
        saveTaskDataLoading: false,
        errorMessages: [],
        data: [],
        pageData: {
            pageSize: TASKS_PAGE.pageSize,
            currentPage: 0,
            hasNextPage: false,
        },
        filters: {},
        taskStatus: get(tasksStatusFilterOptions, `${2}.value`),
        taskTableFilter: undefined,
        sortBy: tasksSortByOptions[1].value,
        actionFilterOptions: {},
        actionFilterOptionsLoading: false,
        sortAscending: true,
        activeData: {
            record: {},
            loading: false,
            selectedId: null,
            errorMessages: [],
            invoices: {
                loading: false,
                errorMessages: [],
                data: [],
                pageData: {
                    pageSize: DETAILS_TAB.INVOICE_LIST.pageSize,
                    currentPage: 0,
                    hasNextPage: false,
                },
                filters: {},
                sortBy: commonInvoiceListInvoicesSortOptions[0].value, //tasksSortByOptions[0].value,
                sortAscending: true,
                invoiceState: get(invoicesStateFilterOptions, `${1}.value`),
            },
            schedule: {
                loading: false,
                errorMessages: [],
                data: [],
                pageData: {
                    pageSize: DETAILS_TAB.PAYMENT_PLAN_SCHEDULE_LIST.pageSize,
                    currentPage: 0,
                    hasNextPage: false,
                },
                filters: {},
                sortBy: paymentScheduleSortByOptions[0].value, // 2 for Invoice number
                sortAscending: true,
            },
        },
        notifyCustomersLoading: false,
        compactView: false,
        compactViewLoading: false,
        toggledExpandStatusIds: [],
    },
    loading: false,
    saveTaskDataLoading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: TASKS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {},
    taskStatus: get(tasksStatusFilterOptions, `${2}.value`),
    tableFilter: undefined,
    sortBy: tasksSortByOptions[1].value,
    actionFilterOptions: {},
    actionFilterOptionsLoading: false,
    sortAscending: true,
    activeData: {
        record: {},
        loading: false,
        selectedId: null,
        errorMessages: [],
    },
    notifyCustomersLoading: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<TasksState> = (state = initialState, action) => {
    switch (action.type) {
        case TasksActionTypes.GET_TASKS_REQUEST: {
            return { ...state, loading: true };
        }
        case TasksActionTypes.GET_TASKS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.data, {
                    $set: action.payload.data,
                });
            } else {
                // update(state.data, {
                //     $push: action.payload.data,
                // });
                newDataState = uniqWith(
                    concat(state.data, action.payload.data),
                    isEqual
                );
            }
            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case TasksActionTypes.GET_TASKS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }

        /**
         * Invoice list here
         */
         case TasksActionTypes.GET_TASK_TICKET_INVOICES_REQUEST: {
            const newInvoicesState = update(
                state.activeTasks.activeData.invoices,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: { $set: newTaskActiveDataState },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.GET_TASK_TICKET_INVOICES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(
                    state.activeTasks.activeData.invoices.data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeTasks.activeData.invoices.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newInvoicesState = update(
                state.activeTasks.activeData.invoices,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.activeTasks.activeData.invoices
                                .errorMessages,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.GET_TASK_TICKET_INVOICES_ERROR: {
            const newInvoicesState = update(
                state.activeTasks.activeData.invoices,
                {
                    $merge: {
                        loading: false,
                        data: initialState.activeTasks.activeData.invoices.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        
        case TasksActionTypes.UPDATE_TASKS_FILTERS: {
            return { ...state, filters: action.payload };
        }
        case TasksActionTypes.UPDATE_TASKS_SORT_BY_AND_STATE: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                taskStatus: action.payload.taskStatus,
                sortAscending: action.payload.sortAscending,
            };
        }
        case TasksActionTypes.UPDATE_TASKS_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }
        case TasksActionTypes.UPDATE_TASK_TICKET_INVOICES_FILTERS: {
            const newInvoicesState = update(
                state.activeTasks.activeData.invoices,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );
                
            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });
          
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.CLEAR_TASKS_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                tableFilter: initialState.tableFilter,
            };
        }
        case TasksActionTypes.CLEAR_TASKS_STATE_DATA: {
            return {
                ...initialState,
            };
        }

        //Single Record
        case TasksActionTypes.SET_TASK_SELECTED_ID_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                    },
                },
            });

            return newState;
        }

        case TasksActionTypes.SET_TASK_SELECTED_ID_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return newState;
        }

        case TasksActionTypes.GET_TASK_DATA_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: true,
                    },
                },
            });

            return newState;
        }

        case TasksActionTypes.GET_TASK_DATA_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages: initialState.activeData.errorMessages,
                    },
                },
            });

            return newState;
        }

        case TasksActionTypes.GET_TASK_DATA_ERROR: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return newState;
        }

        case TasksActionTypes.NOTIFY_CUSTOMERS_REQUEST: {
            return {
                ...state,
                notifyCustomersLoading: true,
            };
        }

        case TasksActionTypes.NOTIFY_CUSTOMERS_RESPONSE: {
            return {
                ...state,
                notifyCustomersLoading: false,
            };
        }

        case TasksActionTypes.CLEAR_TASK_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as tasksReducer };
