import { API, graphqlOperation } from 'aws-amplify';
import { get } from 'lodash';
import { all, call, fork, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import { API_NAME } from '../../config/config';
import queries from '../../graphql/queries.graphql';
import { DynamicObject } from '../../utils/commonInterfaces';
import { UsersActionTypes } from './types';

export const getCurrentUser = (state: ApplicationState) =>
    state.auth.currentUser;

/**
 * Function that calls the API for getting the weekly notifications status.
 * @param param0
 */
function* handleGetUserWeeklyNotificationsStatusRequest({
    payload: callback,
}: any) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_BLACKLIST_TASKS_REPORT)
        );

        const IsBlacklisted = get(res.data, 'GetBlacklistTasksReport');

        const response = {
            IsSuccess: true,
            IsBlacklisted,
        };

        callback(response);
    } catch (err) {
        const returnData = get(err.response, 'data')
            ? err.response.data
            : { Messages: [err.message] };
        returnData.IsSuccess = false;
        callback(returnData);

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
    }
}

/**
 * Function called when proceeding with notify customers for communication tasks.
 * @param param0
 */
function* handleUpdateUserWeeklyNotificationsRequest({
    payload: sagaPayload,
}: any) {
    const { status, callback } = sagaPayload;
    const payload = {
        IsBlacklisted: !status,
    };
    try {
        // To call async functions, use redux-saga's `call()`.
        yield call([API, 'post'], API_NAME, '/task/blacklisttasksreport', {
            body: payload,
        });

        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function* watchGetUserWeeklyNotificationsStatusRequest() {
    yield takeLatest(
        UsersActionTypes.GET_USER_WEEKLY_NOTIFICATION_STATUS_REQUEST,
        handleGetUserWeeklyNotificationsStatusRequest
    );
}

function* watchUpdateUserWeeklyNotificationsRequest() {
    yield takeLatest(
        UsersActionTypes.UPDATE_USER_WEEKLY_NOTIFICATION_REQUEST,
        handleUpdateUserWeeklyNotificationsRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* usersSaga() {
    yield all([
        fork(watchGetUserWeeklyNotificationsStatusRequest),
        fork(watchUpdateUserWeeklyNotificationsRequest),
    ]);
}

export default usersSaga;
